<template>
  <div class="subdetail">
    <div class="contant">
      <div class="top">
        <div class="left">
          <div class="title">{{ infoData.name }}</div>
          <div class="addrtext">{{ infoData.address }}</div>
        </div>
        <div class="guide">
          <div class="icon" @click="guildeClick">
            <img src="@/assets/img/pos.png" alt="" />
          </div>
        </div>
      </div>
      <div class="inputlist">
        <div class="item">
          <div class="tit">所属行业</div>
          <div class="text">{{ infoData.industry }}</div>
        </div>
        <div class="item">
          <div class="tit">门店联系人</div>
          <div class="text" @click="telecall(infoData.mobile)">
            {{ infoData.person }}/<span>{{ infoData.mobile }}</span>
          </div>
        </div>
      </div>
      <div class="logslist" v-if="list.length !== 0">
        <div class="tit">公海记录</div>
        <div class="list">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="listload"
          >
            <div class="head">
              <div class="item">放入公海原因</div>
              <div class="item">放入时间</div>
            </div>
            <div class="tbody">
              <div class="item" v-for="(item, index) in list" :key="index">
                <div class="text">
                  {{ item.content }}
                </div>
                <div class="time">{{ item.time_text }}</div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </div>
    <div class="btns">
      <button
        type="button"
        v-for="(item, index) in buttomBtn"
        :key="index"
        @click="toClickBtn(item)"
      >
        {{ item.title }}
      </button>
    </div>

    <van-popup v-model:show="editshow" position="bottom" round>
      <editorBox @closePup="closePup" @subEdit="subEdit" :editData="editData" :infoData="infoData"/>
    </van-popup>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import API from "@/axios";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import editorBox from "@/views/StoreDetail/component/editorBox";
import mitts from "@/bus";

const route = useRoute();
const router = useRouter();

const buttomBtn = ref([]);
const infoData = ref({});
const editshow = ref(false);
const editData = ref({});

const lng = ref('');
const lat = ref('');

const loading = ref(false);
const finished = ref(false);
const list = ref([]);
const onLoad = () => {
  getinfo();
  finished.value = true;
  loading.value = false;
};

function telecall(val) {
  window.location.href = "tel://" + val;
}

function getinfo() {
  API({
    url: "/api/storeDetail",
    method: "post",
    data: {
      store_id: route.query.id,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      buttomBtn.value = res.data.data.footer_button;
      infoData.value = res.data.data.info;
      list.value = res.data.data.log;
      editData.value = res.data.data.edit;
      
      lng.value = res.data.data.info.lng;
      lat.value = res.data.data.info.lat;
    }else{
      Toast.fail(res.data.msg);
      router.go(-1);
    }
  });
}

//认领门店
function claimStore() {
  API({
    url: "/api/claimStore",
    method: "post",
    data: {
      store_id: route.query.id,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      editshow.value = true;
      Toast.success(res.data.msg);
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function toClickBtn(item) {
  let valname = item.val.substring(
    item.val.lastIndexOf("/") + 1,
    item.val.lastIndexOf("?")
  );
  if (valname == "claimStore") {
    claimStore();
  } else {
    let url = item.val;
    if (url.indexOf("?") > -1) {
      url = url + "&id=" + item.id;
    } else {
      url = url + "?id=" + item.id;
    }
   
    router.push({
      path: "/event/event",
      query: { url: encodeURIComponent(url) },
    });

    mitts.emit("showLoading");
  }
}

function closePup() {
  editshow.value = false;
}

function subEdit() {
  router.push({
    path: "/StoreManagement",
    query: {},
  });
  editshow.value = false;
}

function guildeClick() {
  window.location.href = "https://uri.amap.com/marker?position=" + lng.value + "," + lat.value + "";
}

getinfo();

onMounted(()=>{
  setTimeout(() => {
    mitts.emit("closeLoading");
    mitts.emit("toptitle", false);
  }, 500);
})
</script>

<style lang="less" scoped>
.subdetail {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100vw;
  background: #f5f5f5;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}
.contant {
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 0 20px #d7d7d7;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;
}
.top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
  .left {
    flex: 1;
    min-width: 0;
    margin-right: 10px;
    .title {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .addrtext {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      height: @subtitle-size;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }
  .guide {
    width: 80px;
    height: 80px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    overflow: hidden;
    .icon {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
  }
}
.inputlist {
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .tit {
      font-size: @font-size;
      line-height: @font-size*2;
      color: @font-color;
      margin-right: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .text {
      font-size: @font-size;
      line-height: @font-size*2;
      color: @font-secondary-color;
      flex: 1;
      min-width: 0;
      display: flex;
      justify-content: flex-end;
      span {
        color: @theme-secondary-color;
        font-weight: bolder;
        font-family: "Regular";
      }
      .pic {
        width: 140px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
.btns {
  display: flex;
  margin-bottom: 20px;
  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }
  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
    flex: 2;
  }
}
.logslist {
  flex-grow: 1;
  height: 10px;
  display: flex;
  flex-flow: column;
  .tit {
    color: @font-color;
    font-size: @subtitle-size;
    line-height: @subtitle-size;
    font-weight: bolder;
    font-family: "Regular";
    padding: 20px 0;
    border-top: 1px solid #dfdfdf;
  }
  .list {
    border-radius: 10px;
    overflow-y: auto;
    flex-grow: 1;
    height: 10px;
    .head {
      display: flex;
      background: #e5ebff;
      .item {
        flex: 2;
        border-left: 1px solid #d7d7d7;
        padding: 20px 10px;
        box-sizing: border-box;
        text-align: center;
        font-weight: bolder;
        font-family: "Regular";
      }
      .item:last-of-type {
        flex: 1;
        border-right: 1px solid #d7d7d7;
      }
    }
    .tbody {
      overflow: hidden;
      .item {
        display: flex;
        border-bottom: 1px solid #d7d7d7;
        .text {
          padding: 15px 10px;
          box-sizing: border-box;
          flex: 2;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-left: 1px solid #d7d7d7;
        }
        .time {
          padding: 15px 10px;
          box-sizing: border-box;
          flex: 1;
          text-align: center;
          border-left: 1px solid #d7d7d7;
          border-right: 1px solid #d7d7d7;
        }
      }
    }
  }
}
</style>
